import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from '../images/datwyler.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Thank you" />
    <header className={`c-header`}>
      <a href={`https://datwyler.com`} className={`c-logo`}>
        <img src={logo} alt={`Datwyler`} className={`c-logo__img`} />
      </a>
      <a className={`c-header__cta`} href={`mailto:genny.fiore@datwyler.com`}>Mail us</a>
    </header>
    <div className={`c-thankyou`}>
      <div className={`c-thankyou__wrapper`}>
        <div className="c-thankyou__intro">
          <h1><strong>Thank you</strong> for using <strong>Design Your Seal</strong>!</h1>
          <p className={`p--intro`}>You will receive an e-mail shortly to confirm your product design and shipping details.</p>
          <a href={`/`} title="Go back to homepage?">Go back to homepage</a>
          <a href={`https://www.datwyler.com/en/home/`} title="Visit Datwyler's website">Visit Datwyler's website</a>
          <a href={`mailto:genny.fiore@datwyler.com`} title="Questions?">Questions?</a>
        </div>
        <footer className={`c-footer`}>
          <ul className={`c-footer__list`}>
            <li className={`c-footer__list-item`}><a className={`c-footer__list-link`} href={`https://www.datwyler.com`}>Datwyler.com</a></li>
            <li className={`c-footer__list-item`}><a className={`c-footer__list-link`} href={`https://www.datwyler.com/en/privacy-policy/`}>Privacy Policy</a></li>
          </ul>
        </footer>
      </div>
    </div>

  </Layout>
)

export default IndexPage
